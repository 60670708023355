import React from 'react'
import { Footer, Header } from './'
import { ScrollRestoration } from '..'

import { getLang } from '../../utils/helpers'
import datastore from '../../datastore'

// Actually starting analytics up
function addAnalytics () {
  window.dataLayer = window.dataLayer || []

  window.gtag = function gtag () {
    window.dataLayer.push(arguments)
  }

  window.gtag('js', new Date())
  window.gtag('config', window.GA_MEASUREMENT_ID)
}

/**
 * Default site layout component
 */
const DefaultLayout = ({ children }) => {
  const lang = getLang(window.location.pathname)

  // Get the global app data
  React.useEffect(() => {

    addAnalytics()
    // Ref param
    const query = new URLSearchParams(window.location.search)
    datastore.ref = query.get('ref') || 'Website'

    // Tiny i18n :)
    let lang = Object.keys(datastore.Resources).find(_ => _ === navigator.language.toLowerCase().split('-')[0])
    if(!lang) lang = 'en'
    datastore.t = (key) => {
      return datastore.Resources[lang][key]
    }

  }, [lang])

  return (
    <>
      <ScrollRestoration appNodeId='root' />
      <Header />
      <main key={'main-' + lang}>
        {children}
      </main>
      <Footer />
    </>
  )
}

export default DefaultLayout
