import React from 'react'
import { Helmet } from 'react-helmet'
import {
  BrowserRouter,
  Route,
  Switch
} from 'react-router-dom'
import DefaultLayout from './components/layout/DefaultLayout'

const ContentPage = React.lazy(() => import('./pages/ContentPage'))

const NotFound = React.lazy(() => import('./pages/NotFound'))

const App = () => {

  return (
    <>
      <Helmet>
      </Helmet>
      <BrowserRouter>
        {/* Switch is here only to force Render of DefaultLayout when lang change */}
        <Switch>
          <DefaultLayout>
            <React.Suspense fallback={<span />}>
              <Switch>
                <Route exact path='/404' component={NotFound} />

                <Route exact path='/:lang([a-z]{2}-[^/]{2,3})/:uid'><ContentPage prismicType='content_page' /></Route>

                <Route exact path='/:uid'><ContentPage prismicType='content_page' /></Route>

                <Route component={NotFound} />
              </Switch>
            </React.Suspense>
          </DefaultLayout>
        </Switch>
      </BrowserRouter>
    </>
  )
}

export default App
