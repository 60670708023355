import React from 'react'

import './Footer.sass'

const Footer = () => {
  return (
    <footer className='footer'>
      Card Me • Powered by <a href='https://ucaya.com'>UCAYA</a>
    </footer>
  )
}

export default Footer
