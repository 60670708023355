import { useLocation } from 'react-router-dom'
import datastore from '../datastore'

export const getCookie = (name) => {
  const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)')
  return v ? v[2] : null
}

export const setCookie = (name, value, days = [365], domain) => {
  const d = new Date()
  let cookie = name + '=' + value
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days)
  // document.cookie = name + '=' + value + ';path=/;expires=' + d.toGMTString()
  if (domain) {
    cookie = cookie + ';domain=' + domain
  }
  cookie = cookie + ';path=/;expires=' + d.toGMTString()
  document.cookie = cookie
}

export const deleteCookie = (name) => { setCookie(name, '', -1) }

export const getDefaultLang = () => {
  return datastore.apiDoc.languages[0].id
}

export const getUserLang = () => {
  return getCookie('nf_lang')
}

export const setUserLang = (lang) => {
  setCookie('nf_lang', lang.toLowerCase())
}

export const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

export const getPathWithCurrentLang = (lang, path) => {
  return (lang ? '/' + lang : '') + path
}

export const createRegexLang = () => /^\/([a-z]{2}-[^/]{2,3})(\/|$)/g

export const getLang = (path) => {
  const matchLang = createRegexLang().exec(path)
  return matchLang ? matchLang[1] : null
}

export const socialShares = {
  twitter: (link = '', message = '') =>
      `https://twitter.com/intent/tweet/?text=${encodeURIComponent(
        message
      )}&url=${encodeURIComponent(link)}`,
  facebook: (link = '') =>
      `https://facebook.com/sharer/sharer.php?u=${encodeURIComponent(link)}`,
  google: (link = '') =>
      `https://plus.google.com/share?url=${encodeURIComponent(link)}`,
  tumblr: (link = '') =>
      `http://tumblr.com/widgets/share/tool?canonicalUrl=${encodeURIComponent(
        link
      )}`,
  reddit: (link = '') =>
      `https://reddit.com/submit/?url=${encodeURIComponent(link)}`,
  whatsapp: (link = '', message = '') =>
      `whatsapp://send?text=${encodeURIComponent(message)}%20${encodeURIComponent(
        link
      )}`,
  telegram: (link = '', message = '') =>
      `https://telegram.me/share/url?text=${encodeURIComponent(
        message
      )}&url=${encodeURIComponent(link)}`,
  vk: (link = '', message = '') =>
      `http://vk.com/share.php?title=${encodeURIComponent(
        message
      )}&url=${encodeURIComponent(link)}`,
  hacker: (link = '', message = '') =>
      `https://news.ycombinator.com/submitlink?u=${encodeURIComponent(
        link
      )}&t=${encodeURIComponent(message)}`,
  xing: (link = '', message = '') =>
      `https://www.xing.com/app/user?op=share;url=${encodeURIComponent(
        link
      )};title=${encodeURIComponent(message)}.`,
  mail: (link = '', subject, body) =>
      `mailto:?subject=${encodeURIComponent(
        subject || ''
      )}&body=${encodeURIComponent((body && `${body}\n\n${link}`) || link)}`,
  pinterest: (link = '', message = '') =>
      `https://pinterest.com/pin/create/button/?url=${encodeURIComponent(
        link
      )}&media=${encodeURIComponent(link)}&description=${encodeURIComponent(
        message
      )}`,
  linkedin: (link = '', message = '') =>
      `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
        link
      )}&title=${encodeURIComponent(message)}&summary=${encodeURIComponent(
        message
      )}&source=${encodeURIComponent(link)}`
}

export const trackCurrentPage = () => {
  window.gtag && window.gtag('config', window.GA_MEASUREMENT_ID, { page_path: window.location.pathname })
}

export const getTallyUrlWithParams = (url) => {
  const query = new URLSearchParams()
  query.set('source', datastore.ref || '')
  return url + '?' + query.toString()
}

export const openTallyPopup = (url, options) => {
  const makePopup = (_url) => {
    const re = new RegExp('https?://tally\\.so/r/([a-zA-Z\\d]+)', 'i')
    const m = re.exec(_url)
    const id = m && m[1] ? m[1] : ''

    if(!options) {
      options = {
        emoji: {
          text: '👋',
          animation: 'wave'
        },
        autoClose: 3000
      }
    }
    if(options.overlay === undefined) options.overlay = true
    if(options.hideTitle === undefined) options.hideTitle = true
    if(options.hiddenFields === undefined) {
      options.hiddenFields = {
        source: datastore.ref || ''
      }
    }

    window.Tally.openPopup(id, options)
  }

  loadScriptTally(() => makePopup(url))
}

export const getTypeFormUrlWithParams = (url) => {
  const query = new URLSearchParams()
  query.set('source', datastore.ref || '')
  return url + '?' + query.toString()
}

export const openTypeFormPopup = (url) => {
  const makePopup = (_url) => {
    window.typeformEmbed.makePopup(_url, {
      mode: 'popup',
      autoOpen: true
    })
  }

  loadScriptTypeForm(() => makePopup(url))
}

const loadExternalScript = (url, id, type, callback) => {
  const existingScript = document.getElementById('id')
  if (!existingScript) {
    const script = document.createElement('script')
    script.src = url
    script.id = id
    if (type) {
      script.type = type
    }
    document.body.appendChild(script)
    script.onload = () => {
      if (callback) callback()
    }
  }
  if (existingScript && callback) callback()
}

export const loadScriptTally = (callback) => {
  return loadExternalScript('https://tally.so/widgets/embed.js', 'tallyEmbed', '', callback)
}

export const loadScriptTypeForm = (callback) => {
  return loadExternalScript('https://embed.typeform.com/embed.js', 'typeformEmbed', '', callback)
}

export const loadScriptModelViewer = (callback) => {
  return loadExternalScript('https://unpkg.com/@google/model-viewer/dist/model-viewer.min.js', 'googleModelViewer', 'module', callback)
}
