import React from 'react'
import { Helmet } from 'react-helmet'

const Meta = ({ pageDoc }) => {
  const pageUrl = window.location.href
  const pageTitle = getPageTitle(pageDoc) || window.APP_NAME
  const pageDescription = getPageDescription(pageDoc)

  return (
    <Helmet htmlAttributes={{ lang: pageDoc.lang }}>
      <title>{window.APP_NAME + ' • ' + pageTitle}</title>
      <meta name='description' content={pageDescription || ''} />

      {renderCanonical(pageUrl)}
    </Helmet>
  )
}

function getPageTitle (pageDoc) {
  let value = pageDoc.meta_title

  return value
}

function getPageDescription (pageDoc) {
  let value = pageDoc.meta_description

  return value
}

function renderCanonical (url) {
  if (url.endsWith('/')) {
    url = url.substring(0, url.length - 1)
  }
  return <link rel='canonical' href={url} />
}

export default Meta
