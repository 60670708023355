// Global app data
const datastore = {}

datastore.Resources = {
    fr: {
        'profile.meta.title': '',
        'profile.meta.description': 'Restons en contact',
        'profile.actions.openVCard.label': 'Ouvrir la vCard',
        'profile.actions.openLinkedIn.label': 'LinkedIn',
        'profile.tallyContactUrl': 'https://tally.so/r/wo9kBO'
    },
    en: {
        'profile.meta.title': '',
        'profile.meta.description': 'Keep in touch',
        'profile.actions.openVCard.label': 'Open vCard',
        'profile.actions.openLinkedIn.label': 'LinkedIn',
        'profile.tallyContactUrl': 'https://tally.so/r/nPRG85'
    }
}

export default datastore
