import React from 'react'

import './Header.sass'

const Header = () => {
  return (
    <header className={'header'}>
    </header>
  )
}

export default Header
